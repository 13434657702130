<template>
  <div class="card-select-devolucion flex-wrap flex-md-nowrap">
    <div class="container-input-checkbox ms-3 mt-3 me-4 align-items-center">
      <input
        id="value-1"
        v-model="isSelect"
        class="input-checkbox"
        type="checkbox"
        checkmark="checkmark"
        :value="true"
      />
      <span class="checkmark"></span>
      <label for="value-1" class="d-flex d-md-none">Seleccionar</label>
    </div>
    <img
      :src="dataProduct.producto_imagen"
      style="aspect-ratio: 8/9; margin-bottom: auto"
    />
    <div class="data-product-return col">
      <p class="mb-0">
        {{
          dataProduct.titulo.toLowerCase().replace(/^\w/, d => d.toUpperCase())
        }}
      </p>
      <span class="sku-color-product-return">
        SKU: {{ dataProduct.producto_sku }} <br />
        Talla: {{ dataProduct.talla }} - {{ dataProduct.color }}
      </span>
      <p class="fw-bold pt-1 mb-0">
        ${{ new Intl.NumberFormat('de-DE').format(dataProduct.precio) }}
      </p>
    </div>
    <div v-if="isSelect" class="d-flex align-items-center">
      <div class="input-simple-clover mb-0">
        <label
          class="label-input"
          :style="selectOption ? 'top: -14px;' : 'opacity: 0; z-index: -1;'"
          >Departamento</label
        >
        <select
          v-model="selectOption"
          type="number"
          :class="invalidSelectOption ? 'invalid-error' : ''"
          class="w-100"
        >
          <option :value="null">Opciones</option>
          <option
            v-for="(item, index) in infoDevolucion.motivos_devolucion"
            :key="index"
            :value="item.id"
          >
            {{
              item.descripcion
                .toLowerCase()
                .replace(/^\w/, d => d.toUpperCase())
            }}
          </option>
        </select>
        <span v-if="invalidSelectOption" class="inavalida-input"
          >Campo requerido</span
        >
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  props: {
    dataProduct: {
      type: Object,
      required: false,
      default: () => {},
    },
    invalidSelectOption: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      isSelect: false,
      selectOption: null,
    }
  },
  computed: {
    ...mapState('user', ['infoDevolucion']),
  },
  watch: {
    isSelect() {
      if (this.isSelect) {
        let data = {
          pedido_detalle_id: this.dataProduct.pedido_detalle_id,
          motivo_id: this.selectOption,
        }
        this.$emit('addProduct', data)
      } else {
        let data = {
          pedido_detalle_id: this.dataProduct.pedido_detalle_id,
          motivo_id: this.selectOption,
        }
        this.$emit('deleteProduct', data)
        this.selectOption = null
      }
    },
    selectOption() {
      let data = {
        pedido_detalle_id: this.dataProduct.pedido_detalle_id,
        motivo_id: this.selectOption,
      }
      this.$emit('addProduct', data)
    },
  },
}
</script>
