<template>
  <div class="content-devoluciones mx-auto">
    <div class="content-max-width">
      <h4 v-if="step < 2">DEVOLUCIONES GRATIS:</h4>
      <ul v-if="step < 2" class="mb-5">
        <li>
          El costo del transporte de la devolución y el nuevo envío será asumido
          por Baguer SAS.
        </li>
        <li>Solo puedes realizar una devolución por pedido.</li>
        <li>
          No se aceptan devoluciones de Bisutería (anillos, manillas, pulseras,
          aretes, collares, y cadenas), perfume, ropa interior (tops, boxers,
          pantys, set, medias y bralets), maquillaje y trajes de baño.
        </li>
        <li>
          Puedes solicitar devolución hasta 30 días después de la confirmación
          de la entrega de tu pedido.
        </li>
        <li>
          Recuerda conservar y devolver los empaques y etiquetas originales.
        </li>
      </ul>
      <div v-if="step == 0" class="container-step-1-devolucion">
        <p class="text-center"><b>Iniciar devolución</b></p>
        <p class="text-center mx-auto mb-5" style="max-width: 600px">
          Ingresa tu correo electrónico y el número de pedido para iniciar el
          proceso de devolución.
        </p>
        <div class="col-md-8 col-lg-5 col-xl-4 mb-4 px-md-3 mx-auto">
          <div class="input-simple-clover">
            <label
              :class="validateCorreo ? 'invalid' : ''"
              class="label-input"
              :style="correo ? 'top: -14px;' : 'opacity: 0; z-index: -1;'"
              >Correo electrónico</label
            >
            <input
              id="email"
              v-model="correo"
              :class="validateCorreo ? 'invalid-error' : ''"
              placeholder="Correo electrónico"
              type="email"
              @focus="validateCorreo = false"
            />
            <span v-if="validateCorreo" class="inavalida-input">{{
              correo ? 'Correo invalido' : 'Campo requerido'
            }}</span>
          </div>
        </div>
        <div class="col-md-8 col-lg-5 col-xl-4 mb-4 px-md-3 mx-auto">
          <div class="input-simple-clover">
            <label
              :class="validateNPedido ? 'invalid' : ''"
              class="label-input"
              :style="nPedido ? 'top: -14px;' : 'opacity: 0; z-index: -1;'"
              >Número de pedido</label
            >
            <input
              v-model="nPedido"
              :class="validateNPedido ? 'invalid-error' : ''"
              placeholder="Número de pedido"
              type="number"
              @focus="validateNPedido = false"
            />
            <span v-if="validateNPedido" class="inavalida-input">{{
              nPedido ? 'Número de pedido invalido' : 'Campo requerido'
            }}</span>
          </div>
        </div>
        <div class="col-md-8 col-lg-5 col-xl-4 mb-4 px-md-3 mx-auto">
          <button class="w-100 bnt-primary" @click="validateSendData()">
            Continuar
          </button>
        </div>
        <div
          v-if="errorDevolucion"
          class="col-md-8 col-lg-5 col-xl-4 mb-4 px-md-3 mx-auto"
        >
          <span class="text-error text-center">{{ msgErrorDevolucion }}</span>
        </div>
      </div>
      <div v-else-if="step == 1" class="container-step-2-devolucion">
        <p class="text-center"><b>Iniciar devolución</b></p>
        <p class="text-center">
          Hemos enviado un código de acceso a tu correo electrónico
        </p>
        <div class="col-md-8 col-lg-5 col-xl-4 mb-4 px-md-3 mx-auto">
          <div class="input-simple-clover">
            <label
              :class="validateSecurityCode ? 'invalid' : ''"
              class="label-input"
              :style="securityCode ? 'top: -14px;' : 'opacity: 0; z-index: -1;'"
              >Código de seguridad</label
            >
            <input
              v-model="securityCode"
              :class="validateSecurityCode ? 'invalid-error' : ''"
              placeholder="Código de seguridad"
              type="number"
              @blur="validateBlur('validateSecurityCode')"
              @focus="validateSecurityCode = false"
            />
            <span v-if="validateSecurityCode" class="inavalida-input">{{
              securityCode ? 'Código invalido' : 'Campo requerido'
            }}</span>
          </div>
        </div>
        <div class="col-md-8 col-lg-5 col-xl-4 mb-4 px-md-3 mx-auto">
          <button class="w-100 bnt-primary" @click="validateCodeSend()">
            Verificar
          </button>
        </div>
        <div
          v-if="errorDevolucion"
          class="col-md-8 col-lg-5 col-xl-4 mb-4 px-md-3 mx-auto"
        >
          <span class="text-error text-center">{{ msgErrorDevolucion }}</span>
        </div>
      </div>
      <div v-else-if="step == 2" class="container-step-3-devolucion">
        <span class="btn-volver" @click="goBack()">
          <svg
            id="Shopicons_Light_ArrowBack"
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            viewBox="0 0 48 48"
          >
            <path
              id="Trazado_4823"
              data-name="Trazado 4823"
              d="M0,0H48V48H0Z"
              fill="none"
            />
            <g id="Shopicon">
              <g id="Grupo_5514" data-name="Grupo 5514">
                <path
                  id="Trazado_4824"
                  data-name="Trazado 4824"
                  d="M31.293,7.292,14.586,24,31.293,40.706l1.414-1.414L17.414,24,32.707,8.706Z"
                />
              </g>
            </g>
          </svg>
          Volver
        </span>
        <div class="container-card-pedido mt-3 mb-5">
          <span
            :style="
              `background-color: ${colorsState[3]}; color: ${
                3 > 1 ? '#001F5D' : 'white'
              };`
            "
            class="tag_estado_pedido"
            >Entregado</span
          >
          <div class="info-general-pedido">
            <p class="mb-0">
              Pedido <b>No. {{ infoDevolucion.pedido.pedido_fecha }}</b> <br />
              Fecha de pedido:
              <b
                >{{
                  infoDevolucion.pedido.pedido_fecha.split(' ')[0].split('-')[2]
                }}
                de
                {{
                  getMounthByNumber(
                    infoDevolucion.pedido.pedido_fecha
                      .split(' ')[0]
                      .split('-')[1],
                  )
                }}
                del
                {{
                  infoDevolucion.pedido.pedido_fecha.split(' ')[0].split('-')[0]
                }}</b
              >
              <br />
              Valor pedido:
              <b
                >${{
                  new Intl.NumberFormat('de-DE').format(
                    infoDevolucion.pedido.pedido_total,
                  )
                }}</b
              >
              <br />
              Último estado:
              <b
                >{{ infoDevolucion.pedido.titulo_estado }} -
                {{ infoDevolucion.pedido.fecha_ultimo_estado }}</b
              >
            </p>
          </div>
        </div>
        <hr />
        <p class="mb-5">
          <b>Selecciona los productos y motivo de devolución</b>
        </p>
        <card-product-return
          v-for="(product, index) in infoDevolucion.pedido.productos"
          :key="index"
          :data-product="product"
          :invalid-select-option="
            faltaMotivo.find(
              e => e.pedido_detalle_id == product.pedido_detalle_id,
            )
              ? true
              : false
          "
          @addProduct="addProduct($event)"
          @deleteProduct="deleteProduct($event)"
        />
        <div
          class="w-100 container-input-checkbox mt-5 mb-4 align-items-center"
        >
          <input
            id="value-tyc"
            v-model="tyc"
            :value="true"
            class="input-checkbox"
            type="checkbox"
            checkmark="checkmark"
          />
          <span class="checkmark"></span>
          <label for="value-tyc"
            >Confirmo que los productos que quiero devolver se encuentra en buen
            estado y cumple con los Terminos y condiciones</label
          >
        </div>
        <div class="col-md-8 col-lg-5 col-xl-4 mb-4 px-md-3 ms-auto">
          <button
            class="w-100 bnt-primary"
            :disabled="!tyc"
            :style="!tyc ? 'opacity: .6' : ''"
            @click="validateProductsDevoluciones()"
          >
            Confirmar devolución
          </button>
        </div>
        <div
          v-if="faltaMotivo.length > 0"
          class="col-md-8 col-lg-5 col-xl-4 mb-4 px-md-3 ms-auto"
        >
          <span class="text-error"
            >Algunos productos aun no tienen motivo de devolución</span
          >
        </div>
        <div
          v-if="errorDevolucion"
          class="col-md-8 col-lg-5 col-xl-4 mb-4 px-md-3 ms-auto"
        >
          <span class="text-error">{{ msgErrorDevolucion }}</span>
        </div>
      </div>
      <div v-else-if="step == 3" class="py-4">
        <div class="my-5">
          <span class="btn-volver" @click="goBack()">
            <svg
              id="Shopicons_Light_ArrowBack"
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              viewBox="0 0 48 48"
            >
              <path
                id="Trazado_4823"
                data-name="Trazado 4823"
                d="M0,0H48V48H0Z"
                fill="none"
              />
              <g id="Shopicon">
                <g id="Grupo_5514" data-name="Grupo 5514">
                  <path
                    id="Trazado_4824"
                    data-name="Trazado 4824"
                    d="M31.293,7.292,14.586,24,31.293,40.706l1.414-1.414L17.414,24,32.707,8.706Z"
                  />
                </g>
              </g>
            </svg>
            Volver
          </span>
        </div>
        <h4 class="col-lg-6 mx-auto text-center">
          HEMOS RECIBIDO TU SOLICITUD DE DEVOLUCIÓN
        </h4>
        <p class="col-lg-6 mx-auto text-center mb-4">
          Se ha generado tu orden de devolución, un asesor se comunicará contigo
          y te brindará toda la información para continuar con el proceso de
          devolución.
        </p>
        <div class="col-md-8 col-lg-5 col-xl-4 mb-4 px-md-3 mx-auto">
          <button class="w-100 bnt-primary" @click="goBack()">
            Finalizar proceso
          </button>
        </div>
      </div>
      <div class="content-footer-info">
        <span
          >Si tienes alguna duda o necesitas ayuda, comunícate con nuestra línea
          de atención Whatsapp
          <b
            ><a
              style="color: black"
              href="https://api.whatsapp.com/send?phone=573208743430&text=Hola!%20tengo%20una%20duda%20me%20ayudas!"
            >
              320 874 3430</a
            ></b
          ></span
        >
      </div>
    </div>
  </div>
</template>
<script>
import CardProductReturn from '@/components/returns/CardProductReturn'
import { mapActions, mapState } from 'vuex'
export default {
  components: { CardProductReturn },
  data() {
    return {
      step: 0,
      correo: '',
      validateCorreo: false,
      nPedido: null,
      validateNPedido: false,
      securityCode: '',
      validateSecurityCode: false,
      colorsState: ['#005BC4', '#005BC4', '#005BC4', '#C6ECBB', '#FFC8C8'],
      productsReturn: [],
      faltaMotivo: [],
      tyc: false,
    }
  },
  computed: {
    ...mapState('user', [
      'dataUser',
      'errorDevolucion',
      'msgErrorDevolucion',
      'infoDevolucion',
    ]),
    /* eslint-disable */
    validEmail() {
      const re =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(this.correo)
    },
  },
  async mounted() {
    if (this.$route.params.idPedido && this.dataUser) {
      this.nPedido = this.$route.params.idPedido
      this.correo = this.dataUser.correo
      let data = {
        pedidoId: this.$route.params.idPedido,
        email: this.dataUser.correo,
      }
      await this.validatePedidoDevolucion(data).then(() => {
        this.step += 2
      })
    }
  },
  methods: {
    ...mapActions('user', [
      'validatePedidoDevolucion',
      'validateTokenDevolucion',
      'enviarDevolucion',
    ]),
    resetData() {
      this.step = 0
      this.correo = ''
      this.validateCorreo = false
      this.nPedido = null
      this.validateNPedido = false
      this.securityCode = ''
      this.validateSecurityCode = false
      this.productsReturn = []
      this.faltaMotivo = []
      this.tyc = false
    },
    getMounthByNumber(mount) {
      switch (mount) {
        case '01':
          return 'Enero'
          break
        case '02':
          return 'Febrero'
          break
        case '03':
          return 'Marzo'
          break
        case '04':
          return 'Abril'
          break
        case '05':
          return 'Mayo'
          break
        case '06':
          return 'Junio'
          break
        case '07':
          return 'Julio'
          break
        case '08':
          return 'Agosto'
          break
        case '09':
          return 'Septiembre'
          break
        case '10':
          return 'Octubre'
          break
        case '11':
          return 'Nombiembre'
          break
        case '12':
          return 'Diciembre'
          break
        default:
          return mount
          break
      }
    },
    addProduct(data) {
      if (
        this.productsReturn.find(
          (e) => e.pedido_detalle_id == data.pedido_detalle_id,
        )
      ) {
        this.productsReturn = this.productsReturn.map((e) =>
          e.pedido_detalle_id == data.pedido_detalle_id ? data : e,
        )
      } else {
        this.productsReturn.push(data)
      }
    },
    deleteProduct(data) {
      this.productsReturn = this.productsReturn.filter(
        (e) => e.pedido_detalle_id != data.pedido_detalle_id,
      )
    },
    async validateSendData() {
      if (this.validEmail && this.nPedido && this.nPedido.length > 3) {
        this.validateNPedido = false
        this.validateCorreo = false
        let data = {
          pedidoId: this.nPedido,
          email: this.correo,
        }
        await this.validatePedidoDevolucion(data).then(() => {
          if (this.errorDevolucion) {
            return
          }
          if (this.infoDevolucion) {
            this.step += 2
          } else {
            this.step += 1
          }
        })
      } else {
        this.validateCorreo = !this.validEmail
        this.validateNPedido = !(this.nPedido && this.nPedido.length > 3)
      }
    },
    validateCodeSend() {
      if (this.securityCode.length > 3) {
        this.validateSecurityCode = false
        let data = {
          pedidoId: this.nPedido,
          email: this.correo,
          codigo: this.securityCode,
        }
        this.validateTokenDevolucion(data).then(() => {
          if (!this.errorDevolucion) {
            this.step += 1
          }
        })
      } else {
        this.validateSecurityCode = true
      }
    },
    async validateProductsDevoluciones() {
      if (this.productsReturn.find((e) => e.motivo_id == null)) {
        this.faltaMotivo = this.productsReturn.filter(
          (p) => p.motivo_id == null,
        )
      } else {
        this.faltaMotivo = []
        let data = {
          pedidoId: this.nPedido,
          email: this.correo,
          codigo: this.securityCode,
          pedidoDetalle: this.productsReturn,
        }
        await this.enviarDevolucion(data).then(() => {
          if (!this.errorDevolucion) {
            window.scrollTo(0, 0)
            this.step += 1
          }
        })
      }
    },
    goBack() {
      if (this.$route.name == 'ProfileOrders') {
        this.$emit('cleanOrder', null)
        this.resetData()
      } else {
        this.resetData()
        this.$route.params.idPedido ? this.$router.go(-1) : this.$router.go(0)
      }
    },
  },
}
</script>
